import { useState } from 'react';
import { useQuery } from 'react-query';
import { Loading } from '../components/loading';
import { words } from '../constants/translate';
import { Container, Button, Alert, Modal } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../helper/authUtils';
import { GetAllSupplier } from '../redux/services/supplierService';
import { SupplierTable } from '../components/supplierTable';
import AddNewSupplierForm from '../components/addNewSupplierForm';
import { FiArrowRight } from 'react-icons/fi';
import CustomModal from '../components/customModal';

export default function Suppliers() {
  const user = getUser()

  const [showNewRequest, setShowNewRequest] = useState(false);

  const { data: suppliers_data, status: suppliers_status, refetch: suppliers_refetch } = useQuery('suppliers', GetAllSupplier);
  const navigate = useNavigate();
  
  const handleCloseNewRequestModal = () => {
    setShowNewRequest(false);
    suppliers_refetch();
  };

  const handleShowNewRequestModal = () => {
    setShowNewRequest(true);
  };

  if (suppliers_status === 'error' || suppliers_status === 'idle'){
    return <Alert severity="error">{words.something_wrong}</Alert>
  }

  if (suppliers_status === 'loading'){
    return <Loading/>
  }

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <>
      <Container maxWidth={false} sx={{ mt: 2, display: 'flex', justifyContent: 'flex-start' }}>
      <Button onClick={handleGoBack} startIcon={<FiArrowRight />} variant="contained">
          {words.back}
        </Button>
      </Container>

      <Container maxWidth={false} sx={{ my: 4, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="success"
          startIcon={<AddCircleIcon />}
          onClick={handleShowNewRequestModal}
        >
          {words.add_new_supplier}
        </Button>
      </Container>

      <Container maxWidth={false} sx={{ my: 4 }}>
        <h3>{words.supplier_page}</h3>
        <SupplierTable suppliers={suppliers_data.data} refresh={suppliers_refetch} />
      </Container>

            
      <CustomModal onHide={handleCloseNewRequestModal} show={showNewRequest} title={words.add_new_request}>
        <AddNewSupplierForm refresh={suppliers_refetch}/>
      </CustomModal>
    </>
  );
}