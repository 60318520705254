import React, { useMemo, useState } from 'react';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { MRT_Localization_HE } from 'material-react-table/locales/he';
import { Box, IconButton, Tooltip } from '@mui/material';
import { Visibility, Cancel, Delete } from '@mui/icons-material';
import { words } from '../constants/translate';
import { REQUEST_API } from '../models/request';
import CustomModal from './customModal';
import { CancelRequestForm } from './cancleRequestForm';
import { DeleteRequestForm } from './deleteReuqestFrom';
import { DateFormat, TimeFormat } from '../helper/time';
import { EditRequestForm } from './editRequestForm';

interface RequestTableProps {
    data: REQUEST_API.Data[];
    refresh: () => any;
}

const RequestTable: React.FC<RequestTableProps> = ({ data, refresh }) => {
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const [requestObject, setRequestObject] = useState<REQUEST_API.Data | null>(null);

    const handleShowCancelModal = (object: REQUEST_API.Data) => {
        setRequestObject(object)
        setShowCancelModal(true)

    }
    const handleHideCancelModal = () => {
        setShowCancelModal(false)
        refresh()
    }

    const handleShowDeleteModal = (object: REQUEST_API.Data) => {
        setRequestObject(object)
        setShowDeleteModal(true)

    }
    const handleHideDeleteModal = () => {
        setShowDeleteModal(false)
        refresh()
    }

    const handleShowViewModal = (object: REQUEST_API.Data) => {
        setRequestObject(object)
        setShowViewModal(true)

    }
    const handleHideViewModal = () => {
        setShowViewModal(false)
        refresh()
    }

    const columns = useMemo<MRT_ColumnDef<REQUEST_API.Data>[]>(
        () => [
            {
                accessorKey: 'request.id',
                header: words.request_id,
                Cell: ({ cell }) => <Box sx={{ direction: 'ltr', fontSize: '1rem', textAlign: 'left' }}>{cell.getValue<string>()}</Box>
            },
            {
                accessorKey: 'request.created_by.username',
                header: words.request_send_by,
                Cell: ({ cell }) => <Box sx={{ direction: 'ltr', fontSize: '1rem', textAlign: 'left' }}>{cell.getValue<string>()}</Box>
            },
            {
                accessorKey: 'request.supplier.name',
                header: words.supplier_name,
                Cell: ({ cell }) => <Box sx={{ direction: 'ltr', fontSize: '1rem', textAlign: 'left' }}>{cell.getValue<string>()}</Box>
            },
            {
                accessorKey: 'request.delivery_date',
                header: words.delivery_date,
                Cell: ({ cell }) => <Box sx={{ direction: 'ltr', fontSize: '1rem', textAlign: 'left' }}>{DateFormat(cell.getValue<string>())}</Box>
            },
            {
                accessorKey: 'request.delivery_start_time',
                header: words.delivery_time,
                Cell: ({ cell }) => <Box sx={{ direction: 'ltr', fontSize: '1rem', textAlign: 'left' }}>{TimeFormat(cell.getValue<string>())}</Box>,
            },
            {
                accessorKey: 'request.create_date',
                header: words.request_send_date,
                Cell: ({ cell }) => <Box sx={{ direction: 'ltr', fontSize: '1rem', textAlign: 'left' }}>{DateFormat(cell.getValue<string>())}</Box>,
            },
            {
                accessorKey: 'request.create_time',
                header: words.request_send_time,
                Cell: ({ cell }) => <Box sx={{ direction: 'ltr', fontSize: '1rem', textAlign: 'left' }}>{TimeFormat(cell.getValue<string>())}</Box>,
            },
        ],
        [data]
    );

    return (
        <>
            <MaterialReactTable
                columns={columns}
                data={data}
                enableColumnFilters
                enableGlobalFilter
                enablePagination
                muiTableBodyRowProps={({ row }) => ({
                    sx: {
                        backgroundColor: row.original.request.done
                            ? '#e8f5e9'
                            : row.original.request.canceled
                            ? '#ffebee'
                            : '#fff3e0',
                    },
                })}
                enableRowActions
                renderRowActions={({ row }) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Tooltip title={words.actions}>
                            <IconButton onClick={() => handleShowViewModal(row.original)}>
                                <Visibility color='primary'/>
                            </IconButton>
                        </Tooltip>
                        {!row.original.request.canceled && (
                            <Tooltip title={words.cancel_message_alies}>
                                <IconButton onClick={() => handleShowCancelModal(row.original)}>
                                    <Cancel color='info'/>
                                </IconButton>
                            </Tooltip>
                        )}
                        <Tooltip title={words.delete_action}>
                            <IconButton onClick={() => handleShowDeleteModal(row.original)}>
                                <Delete color='error'/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                positionActionsColumn="last"
                localization={MRT_Localization_HE}
            />

            <CustomModal show={showViewModal} onHide={handleHideViewModal} title={`${requestObject?.request.id}`} >
                <EditRequestForm data={requestObject!} onHide={handleHideViewModal} refresh={refresh}/>
            </CustomModal>

            <CustomModal show={showCancelModal} onHide={handleHideCancelModal} title={`${words.cancel_message_alies}: ${requestObject?.request.id}`} >
                <CancelRequestForm request={requestObject?.request!} onHide={handleHideCancelModal} />
            </CustomModal>

            <CustomModal show={showDeleteModal} onHide={handleHideDeleteModal} title={`${words.delete_message} ${words.request}: ${requestObject?.request.id}`} >
                <DeleteRequestForm request={requestObject?.request!} onHide={handleHideDeleteModal} />
            </CustomModal>

        </>
    );
};

export default RequestTable;
