import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Alert, Typography, Box } from '@mui/material';
import { FiArrowRight } from 'react-icons/fi';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useQuery } from 'react-query';
import { GetAllItems } from '../redux/services/orderService';
import { Loading } from '../components/loading';
import { words } from '../constants/translate';
import CustomModal from '../components/customModal';
import ItemsTable from '../components/itemsTable';
import { AddNewItemForm } from '../components/addNewItemForm';

function ListItem() {
  const [addNewItemModalShow, setAddNewItemModalShow] = React.useState(false);
  const navigate = useNavigate();

  const { data:items, status, refetch:refresh } = useQuery('items', GetAllItems);

  if (status === 'loading') {
    return <Loading/>;
  }

  if(status === 'error' || status === 'idle'){
    return <Alert severity="error">{words.something_wrong}</Alert>
  }
  
  const handleShowNewItemModal = () => {
    setAddNewItemModalShow(true);
  };

  const handleHideNewItemModal = () => {
    setAddNewItemModalShow(false);
    refresh();
  };

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
        <Button onClick={handleGoBack} startIcon={<FiArrowRight />} variant="contained">
          {words.back}
        </Button>
      </Box>

      <Box sx={{ my: 4 }}>
        <Typography variant="h4" align="center">{words.items}</Typography>
      </Box>

      <Box sx={{ my: 4, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="success"
          startIcon={<AddCircleIcon />}
          onClick={handleShowNewItemModal}
        >
          {words.add_new_items}
        </Button>
      </Box>

      <Box sx={{ my: 4 }}>
        <Typography variant="h5">{words.items_list}</Typography>
        <ItemsTable items={items.data} refresh={refresh} />
      </Box>

      <CustomModal onHide={handleHideNewItemModal} show={addNewItemModalShow} title={words.add_new_items}>
        <AddNewItemForm refresh={refresh} />
      </CustomModal>
    </Box>
  );
}

export default ListItem;
