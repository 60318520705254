import React, { useState, useMemo, useCallback } from 'react';
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { MRT_Localization_HE } from 'material-react-table/locales/he';
import { Box, Button } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import CustomModal from './customModal';
import { SUPPLIER_API } from '../models/supplier';
import { words } from '../constants/translate';
import { DeleteSupplierForm } from './deleteSupplierForm';
import EditSupplierForm from './editSupplierForm';

interface SupplierTableProps {
    suppliers: SUPPLIER_API.Supplier[];
    refresh: () => void;
}

export const SupplierTable: React.FC<SupplierTableProps> = ({ suppliers, refresh }) => {
    const [supplierObject, setSupplierObject] = useState<SUPPLIER_API.Supplier | null>(null);
    const [showEditSupplierModal, setShowEditSupplierModal] = useState(false);
    const [showDeleteSupplierModal, setShowDeleteSupplierModal] = useState(false);

    const handleShowEditSupplierModal = useCallback((supplier: SUPPLIER_API.Supplier) => {
        setShowEditSupplierModal(true);
        setSupplierObject(supplier);
    }, []);

    const handleHideEditSupplierModal = useCallback(() => {
        setShowEditSupplierModal(false);
        refresh();
    }, [refresh]);

    const handleShowDeleteSupplierModal = useCallback((supplier: SUPPLIER_API.Supplier) => {
        setShowDeleteSupplierModal(true);
        setSupplierObject(supplier);
    }, []);

    const handleHideDeleteSupplierModal = useCallback(() => {
        setShowDeleteSupplierModal(false);
        refresh();
    }, [refresh]);

    const columns = useMemo<MRT_ColumnDef<SUPPLIER_API.Supplier>[]>(() => [
        { accessorKey: 'name', 
          header: words.supplier_name,
          Cell: ({ cell }) => <Box sx={{ direction: 'ltr', textAlign: 'left' }}>{cell.getValue<string>()}</Box>,
        },
        { 
            accessorKey: 'whatsapp_number', 
            header: words.whatsapp_number,
            Cell: ({ cell }) => <Box sx={{ direction: 'ltr', textAlign: 'left' }}>{cell.getValue<string>()}</Box>,
        },
    ], []);

    const table = useMaterialReactTable({
        columns,
        data: suppliers,
        enableColumnFilters: true,
        enableGlobalFilter: true,
        enablePagination: true,
        enableSorting: true,
        muiTableBodyRowProps: ({ row }) => ({
            sx: { backgroundColor: row.original.is_active ? '#e8f5e9' : '#ffcdd2' },
        }),
        enableRowActions: true,
        positionActionsColumn: 'last',
        renderRowActions: ({ row }) => (
            <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'nowrap', justifyContent: 'flex-start' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleShowEditSupplierModal(row.original)}
                    startIcon={<EditIcon />}
                    sx={{ fontSize: '0.875rem', whiteSpace: 'nowrap' }}
                >
                    {words.edit_action}
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleShowDeleteSupplierModal(row.original)}
                    startIcon={<DeleteIcon />}
                    sx={{ fontSize: '0.875rem', whiteSpace: 'nowrap' }}
                >
                    {words.delete_action}
                </Button>
            </Box>
        ),
        muiTableHeadCellProps: {
            sx: {
                fontSize: '1rem',
                fontWeight: 'bold',
                textAlign: 'right',
            },
        },
        muiTableBodyCellProps: {
            sx: {
                fontSize: '1rem',
                textAlign: 'right',
            },
        },
        localization: MRT_Localization_HE,
        initialState: { density: 'comfortable', showGlobalFilter: true },
        renderEmptyRowsFallback: () => (
            <Box sx={{ textAlign: 'center', p: 2 }}>
                {words.no_data_available}
            </Box>
        ),
    });

    return (
        <>
            <MaterialReactTable table={table} />

            <CustomModal 
                show={showEditSupplierModal} 
                onHide={handleHideEditSupplierModal} 
                title={`${words.edit_action}: ${supplierObject?.name}`}
            >
                <EditSupplierForm supplier={supplierObject!} onHide={handleHideEditSupplierModal}/>
            </CustomModal>

            <CustomModal 
                show={showDeleteSupplierModal} 
                onHide={handleHideDeleteSupplierModal} 
                title={`${words.delete_action}: ${supplierObject?.name}`}
            >
                <DeleteSupplierForm supplier={supplierObject!} onHide={handleHideDeleteSupplierModal} />
            </CustomModal>
        </>
    );
};
