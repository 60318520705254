import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import apiInstance from '../helper/axiosInstance';
import { Form, Button, Alert } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { addDays, isAfter, isBefore, format } from 'date-fns';
import { CustomInput } from './input';
import { words } from '../constants/translate';
import { ORDER_ENDPOINT, STORE_ENDPOINT } from '../constants/endpoints';
import { COMPANY_API } from '../models/company';
import { ORDER_API } from '../models/order';
import CustomModal from './customModal';
import SelectorPrice from './selectorPrice';
import { cites } from '../constants/cites';
import { AutocompleteInput } from './autoCompleteInput';
import { TextArea } from './textArea';
import { REQUEST_API } from '../models/request';
import { GetAllItems } from '../redux/services/orderService';
import { Loading } from './loading';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { areas } from '../constants/cites';

interface EditOrderFormProps {
    onHide: () => void;
    object: ORDER_API.Data;
}
interface Item_list {
    item: ORDER_API.item;
    quantity: number;
}

const validationSchema = Yup.object({
    customerName: Yup.string().required(words.customer_name_required),
    customerPhone: Yup.string().required(words.customer_mobile_number),
    deliveryPlace: Yup.string().required(words.delivery_place_required).oneOf(areas, 'העיר אינה תקינה'),
    deliveryStartTime: Yup.string().required(words.delivery_time_required),
    deliveryEndTime: Yup.string(),
    deliveryDate: Yup.date().required(words.delivery_date_required),
    deliveryPrice: Yup.number().nullable(),
    addressDetails: Yup.string().required(words.fields_required),
    deliveryHeight: Yup.string().required(words.delivery_height_required),
    done: Yup.boolean(),
    note: Yup.string(),
    vehicleObject: Yup.object().nullable(),
});

interface FormValues {
    customerName: string;
    customerPhone: string;
    deliveryPlace: string;
    deliveryStartTime: string;
    deliveryEndTime: string;
    deliveryDate: Date | null;
    deliveryPrice: string;
    addressDetails: string;
    deliveryHeight: string;
    done: boolean;
    note: string;
    vehicleObject: COMPANY_API.FullVehicle | null;
}

export const EditOrderForm: React.FC<EditOrderFormProps> = ({ onHide, object }) => {
    const [items, setItems] = useState<Item_list[]>(() => 
        object.items.map(item => ({
            item: { id: item.id, name: item.item_name },
            quantity: Number(item.quantity)
        }))
    );
    const [selectedItem, setSelectedItem] = useState<string | null>(null);
    const [quantity, setQuantity] = useState<string>('1.0');

    const [error, setError] = useState('');
    const [showVehicleList, setShowVehicleList] = useState(false);

    const { data: items_data, status: items_status } = useQuery('items', GetAllItems);

    const formik = useFormik<FormValues>({
        initialValues: {
            customerName: object.order.customer_name,
            customerPhone: object.order.customer_phone,
            deliveryPlace: object.order.delivery_place,
            deliveryStartTime: object.order.delivery_start_time,
            deliveryEndTime: object.order.delivery_end_time,
            deliveryDate: new Date(object.order.delivery_date),
            deliveryPrice: object.order.delivery_price,
            addressDetails: object.order.address_details,
            deliveryHeight: object.order.delivery_height,
            done: object.order.done,
            note: object.order.note,
            vehicleObject: object.order.vehicle_id,
        },
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });

    const editOrderMutation = useMutation<void, unknown, any>(
        (data) => {
            return apiInstance.patch(STORE_ENDPOINT + ORDER_ENDPOINT + `${object.order.id}/`, data).then(() => {
                onHide();
                toast.success('הפעולה הושלמה בהצלחה');
            });
        },
        {
            onError: () => {
                setError(words.something_wrong);
            },
        }
    );

    const handleChangeQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        const regex = /^[\d.\u0660-\u0669\u06F0-\u06F9\u0030-\u0039]*$/;
        if (!regex.test(inputValue)) {
            return;
        }
        setQuantity(inputValue);
    }

    const addItem = () => {
        if (formik.values.done) return; // Prevent adding items if order is done

        const optionSelected = items_data.data.find((option: ORDER_API.item) => option.name === selectedItem);
        let q_value = Math.round(parseFloat(quantity) * 10) / 10;

        if (q_value < 0.1) {
            return
        }

        if (optionSelected) {
            const existingItem = items.find(item => item.item.id === optionSelected.id);

            if (existingItem) {
                setItems(prev => prev.map(item =>
                    item.item.id === existingItem.item.id
                        ? { ...item, quantity: item.quantity + q_value }
                        : item
                ));
            } else {
                setItems(prev => [...prev, {
                    item: optionSelected,
                    quantity: q_value
                }]);
            }

            setSelectedItem(null);
            setQuantity('1.0');
        }
    };

    const deleteItem = (name: string) => {
        if (formik.values.done) return; // Prevent deleting items if order is done

        const updatedItems = items.filter(option => option.item.name !== name);
        setItems(updatedItems);
    };

    const handleSubmit = (values: FormValues) => {
        if (items.length === 0) {
            setError(words.add_items);
            return;
        }
        setError('');

        const data = {
            customer_name: values.customerName,
            customer_phone: values.customerPhone,
            delivery_place: values.deliveryPlace,
            delivery_start_time: String(values.deliveryStartTime),
            delivery_end_time: values.deliveryEndTime ? String(values.deliveryEndTime) : String(values.deliveryStartTime),
            delivery_date: format(values.deliveryDate!, 'yyyy-MM-dd'),
            delivery_height: values.deliveryHeight,
            delivery_price: values.deliveryPrice,
            address_details: values.addressDetails,
            note: values.note,
            done: values.done,
            vehicle_id: values.vehicleObject?.id,
            items: items.map(item => ({
                    item_id: item.item.id,
                    item_name: item.item.name,
                    quantity: item.quantity,
                })
            ),
        };
        editOrderMutation.mutate(data);
    };

    const handleShowPriceList = () => {
        setShowVehicleList(true)
    }
    const handleHidePriceList = () => {
        setShowVehicleList(false)
    }
    const handlePickVehivle = (vehicle: COMPANY_API.FullVehicle, price: any) => {
        formik.setFieldValue('vehicleObject', vehicle);
        formik.setFieldValue('deliveryPrice', price);
        setShowVehicleList(false)
    }
    const handleDateChange = (date: Date) => {
        const today = new Date();
        const sevenDaysAgo = addDays(today, -7);
        const thirtyDaysFromToday = addDays(today, 30);

        if (isBefore(date, sevenDaysAgo) || isAfter(date, thirtyDaysFromToday)) {
            formik.setFieldError('deliveryDate', words.invalid_date_range);
            formik.setFieldValue('deliveryDate', null);
        } else {
            formik.setFieldError('deliveryDate', '');
            formik.setFieldValue('deliveryDate', date);
        }
    };

    if (items_status === 'loading') {
        return <Loading />;
    }

    return (
        <>
            <Form onSubmit={formik.handleSubmit} style={{textAlign: 'right'}}>
                {error && <Alert variant="danger" className="mb-3">{error}</Alert>}

                <div className="row mb-3">
                    <div className="col-md-6">
                        <AutocompleteInput
                            suggestions={[]}
                            showListOnClick={false}
                            matchedRequired={false}
                            label={words.customer_name}
                            name="customerName"
                            type="text"
                            value={formik.values.customerName}
                            externalOnBlur={formik.handleBlur}
                            onSuggestionSelect={(e) => formik.setFieldValue('customerName', e)}
                            error={formik.errors.customerName}
                        />
                    </div>
                    <div className="col-md-6">
                        <AutocompleteInput
                            suggestions={[]}
                            showListOnClick={false}
                            name='customerPhone'
                            matchedRequired={false}
                            label={words.customer_mobile_number}
                            type="text"
                            value={formik.values.customerPhone}
                            externalOnBlur={formik.handleBlur}
                            onSuggestionSelect={(e) => formik.setFieldValue('customerPhone', e)}
                            error={formik.errors.customerPhone}
                        />
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-6">
                        <AutocompleteInput
                            suggestions={cites}
                            label={words.delivery_place}
                            showListOnClick
                            matchedRequired
                            type="text"
                            value={formik.values.deliveryPlace}
                            onSuggestionSelect={(value) => {
                                formik.setFieldValue('deliveryPlace', value)
                            }}
                            error={formik.errors.deliveryPlace}
                        />
                    </div>
                    <div className="col-md-6">
                        <CustomInput
                            label={words.address}
                            type="text"
                            value={formik.values.addressDetails}
                            onChange={(e) => formik.setFieldValue('addressDetails', e.target.value)}
                            error={formik.errors.addressDetails}
                        />
                    </div>

                </div>

                <div className='row mb-1'>
                    <div className="col-md-6">
                        <CustomInput
                            label={words.delivery_height}
                            type="text"
                            value={formik.values.deliveryHeight}
                            onChange={(event) => formik.setFieldValue('deliveryHeight', event.target.value)}
                            error={formik.errors.deliveryHeight}
                        />
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-6 d-flex flex-column ">
                        <label htmlFor="deliveryDateInput" className="mb-1">
                            {words.delivery_date}
                        </label>
                        <DatePicker
                            id="deliveryDateInput"
                            selected={formik.values.deliveryDate}
                            onChange={handleDateChange}
                            className="form-control"
                            dateFormat="yyyy-MM-dd"
                        />
                        {formik.errors.deliveryDate && <div className="text-danger mt-1">{formik.errors.deliveryDate}</div>}
                    </div>
                </div>

                <div className='row mb-3'>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="deliveryTimeStartInput" className="mb-1">
                                {words.From_time}
                            </label>
                            <input
                                type="time"
                                id="deliveryTimeStartInput"
                                value={formik.values.deliveryStartTime}
                                onChange={(event) => formik.setFieldValue('deliveryStartTime', event.target.value)}
                                className="form-control"
                            />
                            {formik.errors.deliveryStartTime && <div className="text-danger mt-1">{formik.errors.deliveryStartTime}</div>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="deliveryTimeEndInput" className="mb-1">
                                {words.To_time}
                            </label>
                            <input
                                type="time"
                                id="deliveryTimeEndInput"
                                value={formik.values.deliveryEndTime ? formik.values.deliveryEndTime : formik.values.deliveryStartTime}
                                onChange={(event) => formik.setFieldValue('deliveryEndTime', event.target.value)}
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-3">
                        <CustomInput
                            label={words.vehicle_number}
                            type="text"
                            value={formik.values.vehicleObject ? formik.values.vehicleObject.name : words.not_selected}
                            disable={true}
                        />
                    </div>
                    <div className="col-md-3 align-items-center align-self-center mt-3">
                        <Button variant="warning" onClick={() => handleShowPriceList()}>
                            {words.selectVehicle}
                        </Button>
                    </div>
                    <div className="col-md-6">
                        <CustomInput
                            label={words.price}
                            type="text"
                            value={formik.values.deliveryPrice}
                            disable={true}
                        />
                    </div>
                </div>

                <div className='row border m-2'>
                    <div className="p-4">
                        <ul className="list-group mb-3 items-list ">
                            {
                                items.length === 0 ?
                                    <Alert variant='warning'>{words.add_items}</Alert>
                                    :
                                    items.map((option) => (
                                        <li key={option.item.id} className="list-group-item d-flex justify-content-between align-items-center">
                                            {option.item.name}  * {option.quantity}
                                            {!formik.values.done && (
                                                <button 
                                                    className="btn btn-danger btn-sm" 
                                                    onClick={() => deleteItem(option.item.name)} 
                                                    type="button"
                                                >
                                                    Delete
                                                </button>
                                            )}
                                        </li>
                                    ))
                            }
                        </ul>

                        {!formik.values.done && (
                            <div className="d-flex justify-content-center">
                                <Form.Group controlId="item" className="mx-2">
                                    <Form.Label>{words.select_item}</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={selectedItem || ""}
                                        onChange={(e) => setSelectedItem(e.target.value)}
                                    >
                                        <option value="" disabled>
                                            {words.select_item}
                                        </option>

                                        {items_data.data.map((option: ORDER_API.item, index: React.Key | null | undefined) => (
                                            <option key={index} value={option.name}>{option.name}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>

                                <CustomInput
                                    label={words.quantity}
                                    className="mx-2"
                                    value={quantity}
                                    onChange={(e) => handleChangeQuantity(e)}
                                    placeholder="1.0"
                                    disable={selectedItem ? false : true}
                                />

                                <div style={{ height: '40%' }} className='d-flex align-self-center mt-3 '>
                                    <Button
                                        className="responsive-button mx-2"
                                        size='sm'
                                        onClick={addItem}
                                        disabled={selectedItem && quantity.length != 0 ? false : true}
                                        type='button'
                                    >
                                        {words.add_items}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className='row mb-3'>
                    <TextArea
                        label={words.note}
                        value={formik.values.note?formik.values.note:''}
                        onChange={(e) => formik.setFieldValue('note', e.target.value)} />
                </div>

                <div className="row my-2">
                    <div className="col-md-6">
                        <Form.Check
                            inline
                            type="checkbox"
                            label={words.done}
                            checked={formik.values.done}
                            onChange={() => {
                                if (!formik.values.done || window.confirm(words.done)) {
                                    formik.setFieldValue('done', !formik.values.done);
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-center mt-4">
                    <Button
                        variant="primary"
                        className="responsive-button"
                        size="lg"
                        type="submit"
                    >
                        {words.save_changes}
                    </Button>
                </div>
            </Form>


            <CustomModal show={showVehicleList} onHide={handleHidePriceList} size='xl' title={words.price_table_list}>
                <SelectorPrice pick={handlePickVehivle} />
            </CustomModal>
        </>

    );
};