import React, { useMemo } from 'react';
import logistics_logo from '../assets/img/logistics_logo.png'
import price_logo from '../assets/img/price_logo.png'
import users_logo from '../assets/img/users_logo.png'
import companies_logo from '../assets/img/companies_logo.png'
import supplier_logo from '../assets/img/supplier_logo.png'
import request_logo from '../assets/img/request_logo.png'
import materials_logo from '../assets/img/materials_logo.png'
import report_logo from '../assets/img/report_logo.png'

import CardWithOverlay from '../components/card'
import { words } from '../constants/translate';
import { getUser } from '../helper/authUtils';

export interface IDashboardProps {
}

export default function Dashboard(props: IDashboardProps) {
  const user = getUser()

  const dashboardContent = useMemo(() => {
    const isManagerOrSuperuser = user?.is_superuser || user?.role === 'manager';

    const commonCards = (
      <>
        <div className='d-flex flex-column flex-md-row justify-content-start no-gutters'>
          <CardWithOverlay imageUrl={logistics_logo} title={words.orders} link="/orders" />
          <CardWithOverlay imageUrl={request_logo} title={words.request_page} link="/requests" />
        </div>

        <div className='d-flex flex-column flex-md-row justify-content-start no-gutters'>
          <CardWithOverlay imageUrl={companies_logo} title={words.companies} link="/logistics" />
          <CardWithOverlay imageUrl={price_logo} title={words.price_page} link="/price" />
          <CardWithOverlay imageUrl={supplier_logo} title={words.supplier_page} link="/suppliers" />
          <CardWithOverlay imageUrl={materials_logo} title={words.materials} link="/items" />
        </div>
      </>
    );

    if (isManagerOrSuperuser) {
      return (
        <>
          {commonCards}
          <div className='d-flex flex-column flex-md-row justify-content-start no-gutters'>
            <CardWithOverlay imageUrl={users_logo} title={words.users} link="/users" />
            <CardWithOverlay imageUrl={report_logo} title={words.report} link="/report" />
          </div>
        </>
      );
    }

    return commonCards;
  }, [user?.is_superuser, user?.role]);

  return (
    <div className="container-fluid">
      {dashboardContent}
    </div>
  );
}
