import React, { useState, useMemo } from 'react';
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import { MRT_Localization_HE } from 'material-react-table/locales/he';
import { Box, Button } from '@mui/material';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { ORDER_API } from '../models/order';
import { words } from '../constants/translate';
import CustomModal from './customModal';
import { DeleteItemForm } from './deleteItemForm';
import { EditItemForm } from './editItemForm';

interface ItemsTableProps {
    items: ORDER_API.item[];
    refresh: () => any;
}

const ItemsTable: React.FC<ItemsTableProps> = ({ items, refresh }) => {
    const [selectedObject, setSelectedObject] = useState<ORDER_API.item | null>(null);
    const [showItemEditModal, setShowItemEditModal] = useState<boolean>(false);
    const [showItemDeleteModal, setShowItemDeleteModal] = useState<boolean>(false);

    const handleShowEditItemModal = (obj: ORDER_API.item) => {
        setSelectedObject(obj);
        setShowItemEditModal(true);
    };

    const handleHideEditItemModal = () => {
        setShowItemEditModal(false);
        refresh();
    };

    const handleShowDeleteItemModal = (obj: ORDER_API.item) => {
        setShowItemDeleteModal(true);
        setSelectedObject(obj);
    };

    const handleHideDeleteItemModal = () => {
        setShowItemDeleteModal(false);
        refresh();
    };

    const columns = useMemo<MRT_ColumnDef<ORDER_API.item>[]>(
        () => [
            {
                accessorKey: 'name',
                header: 'Name',
                Cell: ({ cell }) => <Box sx={{ direction: 'ltr', textAlign: 'left' }}>{cell.getValue<string>()}</Box>,
            },
            {
                id: 'actions',
                header: 'Actions',
                Cell: ({ row }) => (
                    <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'nowrap', justifyContent: 'flex-start' }}>
                        <Button variant="contained" color="primary"  onClick={() => handleShowEditItemModal(row.original)}>
                            <FaEdit /> {words.edit_action}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={() => handleShowDeleteItemModal(row.original)}>
                            <FaTrash /> {words.delete_action}
                        </Button>
                    </Box>
                ),
            },
        ],
        []
    );

    return (
        <>
            <MaterialReactTable
                columns={columns}
                data={items}
                enableColumnFilters
                enableGlobalFilter
                enablePagination
                enableSorting
                initialState={{ 
                    pagination: { pageSize: 10, pageIndex: 0 },
                    globalFilter: '',
                }}
                muiSearchTextFieldProps={{
                    placeholder: words.search,
                    variant: 'outlined',
                    margin: 'normal',
                    fullWidth: true,
                }}
                localization={MRT_Localization_HE}
            />

            <CustomModal show={showItemEditModal} onHide={handleHideEditItemModal} title='Edit Item'>
                <EditItemForm onHide={handleHideEditItemModal} item={selectedObject!} />
            </CustomModal>

            <CustomModal show={showItemDeleteModal} onHide={handleHideDeleteItemModal} title='Delete Item'>
                <DeleteItemForm id={selectedObject?.id!} item_name={selectedObject?.name!} onHide={handleHideDeleteItemModal} />
            </CustomModal>
        </>
    );
};

export default ItemsTable;
