import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface CustomModalProps {
  show: boolean;
  onHide: () => void;
  title: string;
  size?: 'lg' | 'xl';
  children: React.ReactNode;
}

export const CustomModal: React.FC<CustomModalProps> = ({ show, onHide, title, children, size = 'lg' }) => {
  const maxWidth = size === 'xl' ? 'xl' : 'lg';

  return (
    <Dialog
      open={show}
      onClose={onHide}
      maxWidth={maxWidth}
      fullWidth
      PaperProps={{
        style: { direction: 'rtl' }
      }}
    >
      <DialogTitle sx={{
        m: 0,
        p: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row-reverse'
      }}>
        <Typography variant="h6" component="div" className="modal-title-rtl" sx={{ textAlign: 'right' }}>
          {title}
        </Typography>
        <IconButton aria-label="close" onClick={onHide} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className="modal-body" sx={{ textAlign: 'right' }}>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default CustomModal;