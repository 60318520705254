import React, { useState, useMemo, useCallback } from 'react';
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { MRT_Localization_HE } from 'material-react-table/locales/he';
import { Box, Button } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import CustomModal from './customModal';
import { COMPANY_API } from '../models/company';
import { words } from '../constants/translate';
import { DeleteVehicleForm } from './deleteVehicleForm';
import { EditVehicleForm } from './editVehicleForm';
import { getUser } from '../helper/authUtils';
import VehiclePriceList from './vehiclePriceList';

interface VehicleTableProps {
    vehicles: COMPANY_API.Vehicle[];
    refresh: () => void;
}

export const VehiclesTable: React.FC<VehicleTableProps> = ({ vehicles, refresh }) => {
    const user = getUser();
    const [vehicleObject, setVehicleObject] = useState<COMPANY_API.Vehicle | null>(null);
    const [showEditVehicleModal, setShowEditVehicleModal] = useState(false);
    const [showDeleteVehicleModal, setShowDeleteVehicleModal] = useState(false);
    const [showPrice, setShowPrice] = useState(false);
    const [targetID, setTargetID] = useState('');
    const [targetName, setTargetName] = useState('');

    const handleShowEditVehicleModal = useCallback((vehicle: COMPANY_API.Vehicle) => {
        setShowEditVehicleModal(true);
        setVehicleObject(vehicle);
    }, []);

    const handleHideEditVehicleModal = useCallback(() => {
        setShowEditVehicleModal(false);
        refresh();
    }, [refresh]);

    const handleShowDeleteVehicleModal = useCallback((id: string, name: string) => {
        setShowDeleteVehicleModal(true);
        setTargetID(id);
        setTargetName(name);
    }, []);

    const handleHideDeleteVehicleModal = useCallback(() => {
        setShowDeleteVehicleModal(false);
        setTargetID('');
        setTargetName('');
        refresh();
    }, [refresh]);

    const handleShowPriceList = useCallback((vehicle: COMPANY_API.Vehicle) => {
        setShowPrice(true);
        setVehicleObject(vehicle);
    }, []);

    const handleHidePriceList = useCallback(() => {
        setShowPrice(false);
    }, []);

    const columns = useMemo<MRT_ColumnDef<COMPANY_API.FullVehicle>[]>(() => [
        {
            accessorKey: 'name',
            header: words.vehicle_name,
            Cell: ({ row }) => (
                <Box
                    component="span"
                    sx={{ 
                        cursor: 'pointer', 
                        color: 'primary.main', 
                        '&:hover': { textDecoration: 'underline' },
                        fontSize: '1rem',
                        textAlign: 'left',
                        width: '100%',
                        display: 'block'
                    }}
                    onClick={() => handleShowPriceList(row.original)}
                >
                    {row.original.name}
                </Box>
            ),
        },
        { accessorKey: 'height', header: words.height, Cell: ({ cell }) => <Box sx={{ direction: 'ltr', fontSize: '1rem', textAlign: 'left' }}>{cell.getValue<string>()}</Box> },
        { accessorKey: 'weight', header: words.weight, Cell: ({ cell }) => <Box sx={{ direction: 'ltr', fontSize: '1rem', textAlign: 'left' }}>{cell.getValue<string>()}</Box> },
        { 
            accessorKey: 'driver_mobile_number', 
            header: words.driver_mobile_number,
            Cell: ({ cell }) => <Box sx={{ direction: 'ltr', fontSize: '1rem', textAlign: 'left' }}>{cell.getValue<string>()}</Box>
        },
    ], [handleShowPriceList]);

    const table = useMaterialReactTable({
        columns,
        data: vehicles,
        enableColumnFilters: true,
        enableGlobalFilter: true,
        enablePagination: true,
        enableSorting: true,
        muiTableBodyRowProps: ({ row }) => ({
            sx: {
                backgroundColor: row.original.company?.is_active ? '#e8f5e9' : '#ffcdd2',
            },
        }),
        enableRowActions: true,
        positionActionsColumn: 'last',
        renderRowActions: ({ row }) => (
            <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'nowrap', justifyContent: 'flex-start' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleShowEditVehicleModal(row.original)}
                    startIcon={<EditIcon />}
                    sx={{ fontSize: '0.875rem', whiteSpace: 'nowrap' }}
                >
                    {words.edit_action}
                </Button>
                {(user?.role === 'manager' || user?.is_superuser) && (
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleShowDeleteVehicleModal(`${row.original.id}`, row.original.name)}
                        startIcon={<DeleteIcon />}
                        sx={{ fontSize: '0.875rem', whiteSpace: 'nowrap' }}
                    >
                        {words.delete_action}
                    </Button>
                )}
            </Box>
        ),
        muiTableHeadCellProps: {
            sx: {
                fontSize: '1rem',
                fontWeight: 'bold',
                textAlign: 'center',
            },
        },
        muiTableBodyCellProps: {
            sx: {
                fontSize: '1rem',
                textAlign: 'right',
            },
        },
        localization: MRT_Localization_HE,
        initialState: { density: 'comfortable', showGlobalFilter: true },
    });

    return (
        <>
            <MaterialReactTable table={table} />

            <CustomModal show={showEditVehicleModal} onHide={handleHideEditVehicleModal} title={`${words.edit_vehicle_info}: ${vehicleObject?.name}`}>
                <EditVehicleForm onHide={handleHideEditVehicleModal} vehicle={vehicleObject!} />
            </CustomModal>

            <CustomModal show={showPrice} onHide={handleHidePriceList} title={`${words.price_table_list}: ${vehicleObject?.name}`}>
                <VehiclePriceList vehicle={vehicleObject!} />
            </CustomModal>

            <CustomModal show={showDeleteVehicleModal} onHide={handleHideDeleteVehicleModal} title={`${words.delete_action} ${words.vehicle}: ${targetName}`}>
                <DeleteVehicleForm onHide={handleHideDeleteVehicleModal} id={targetID} name={targetName} />
            </CustomModal>
        </>
    );
};