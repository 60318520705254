import React, { useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import '../assets/CSS/priceTable.css'
import { words } from '../constants/translate';
import { FaEdit } from 'react-icons/fa';
import { PRICE_API } from '../models/price';
import { useQuery } from 'react-query';
import { GetTransportPriceList } from '../redux/services/transportServices';
import { Loading } from './loading';
import { COMPANY_API } from '../models/company';
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { MRT_Localization_HE } from 'material-react-table/locales/he';
import { Box, Button as MuiButton } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';

interface SelectorPriceProps {
   pick:(vehicle:COMPANY_API.FullVehicle, price:any)=>void
}

const SelectorPrice: React.FC<SelectorPriceProps> = ({pick}) => {
  const { data, status } = useQuery('priceData', GetTransportPriceList);

  const columns = useMemo<MRT_ColumnDef<PRICE_API.Price>[]>(
    () => [
      {
        accessorKey: 'from_location',
        header: words.from_location,
        Cell: ({ cell }) => <Box sx={{ fontSize: '1rem', textAlign: 'left' }}>{cell.getValue<string>()}</Box>,
      },
      {
        accessorKey: 'to_location',
        header: words.to_location,
        Cell: ({ cell }) => <Box sx={{ fontSize: '1rem', textAlign: 'left' }}>{cell.getValue<string>()}</Box>,
      },
      {
        accessorKey: 'price',
        header: words.price,
        Cell: ({ cell }) => <Box sx={{ direction: 'ltr', fontSize: '1rem', textAlign: 'left' }}>{cell.getValue<string>()}</Box>,
      },
      {
        accessorKey: 'vehicle.name',
        header: words.vehicle_name,
        Cell: ({ cell }) => <Box sx={{ fontSize: '1rem', textAlign: 'left' }}>{cell.getValue<string>()}</Box>,
      },
      {
        accessorKey: 'vehicle.height',
        header: words.height,
        Cell: ({ cell }) => <Box sx={{ direction: 'ltr', fontSize: '1rem', textAlign: 'left' }}>{cell.getValue<string>()}</Box>,
      },
      {
        accessorKey: 'vehicle.weight',
        header: words.weight,
        Cell: ({ cell }) => <Box sx={{ direction: 'ltr', fontSize: '1rem', textAlign: 'left' }}>{cell.getValue<string>()}</Box>,
      },
      {
        accessorKey: 'vehicle.driver_mobile_number',
        header: words.driver_mobile_number,
        Cell: ({ cell }) => <Box sx={{ direction: 'ltr', fontSize: '1rem', textAlign: 'left' }}>{cell.getValue<string>()}</Box>,
      },
      {
        accessorKey: 'vehicle.company',
        header: words.company_details,
        Cell: ({ cell }) => <Box sx={{ fontSize: '1rem', textAlign: 'left' }}>{cell.getValue<string>()}</Box>,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: data?.data ?? [],
    enableColumnFilters: true,
    enableGlobalFilter: true,
    enablePagination: true,
    enableSorting: true,
    enableRowActions: true,
    positionActionsColumn: 'last',
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'nowrap', justifyContent: 'flex-start' }}>
        <MuiButton
          variant="contained"
          color="primary"
          onClick={() => pick(row.original.vehicle, row.original.price)}
          startIcon={<EditIcon />}
          sx={{ fontSize: '0.875rem', whiteSpace: 'nowrap' }}
        >
          {words.select_vehicle}
        </MuiButton>
      </Box>
    ),
    muiTableHeadCellProps: {
      sx: {
        fontSize: '1rem',
        fontWeight: 'bold',
        textAlign: 'center',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '1rem',
        textAlign: 'right',
      },
    },
    localization: MRT_Localization_HE,
    initialState: { density: 'comfortable', showGlobalFilter: true },
    muiSearchTextFieldProps: {
      placeholder: words.search,
      variant: 'outlined',
      size: 'small',
    },
  });

  if (status === 'loading') {
    return <Loading />;
  }

  return <MaterialReactTable table={table} />;
};

export default SelectorPrice;

