import React, { useState, useMemo } from 'react';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { Box, IconButton, Tooltip } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { words } from '../constants/translate';
import CustomModal from './customModal';
import { ORDER_API } from '../models/order'
import { DeleteOrderForm } from './deleteOrderForm';
import { EditOrderForm } from './editOrderForm';
import { getUser } from '../helper/authUtils';
import { DateFormat, TimeFormat } from '../helper/time';

interface OrdersListTableProps {
    orders: ORDER_API.Data[];
    refresh: () => any;
}

const OrdersListTable: React.FC<OrdersListTableProps> = ({ orders, refresh }) => {
    const user = getUser()
    const [showEditOrderModal, setShowEditOrderModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [DataElement, setDataElement] = useState<ORDER_API.Data | null>(null);

    const handleShowEditOrderModal = (object: ORDER_API.Data) => {
        setShowEditOrderModal(true);
        setDataElement(object);
    };

    const handleHideEditOrderModal = () => {
        setShowEditOrderModal(false);
        refresh();
    };

    const handleShowDeleteOrderModal = (object: ORDER_API.Data) => {
        setShowDeleteModal(true);
        setDataElement(object);
    };

    const handleHideDeleteOrderModal = () => {
        setShowDeleteModal(false);
        refresh();
    };

    const columns = useMemo<MRT_ColumnDef<ORDER_API.Data>[]>(
        () => [
            {
                accessorKey: 'order.id',
                header: words.order_id,
            },
            {
                accessorKey: 'order.customer_name',
                header: words.customer_name,
            },
            {
                accessorKey: 'order.customer_phone',
                header: words.customer_mobile_number,
            },
            {
                accessorKey: 'order.delivery_place',
                header: words.delivery_place,
            },
            {
                accessorKey: 'order.delivery_start_time',
                header: words.delivery_time,
                Cell: ({ cell }) => TimeFormat(cell.getValue<string>()) + ' - ' + (cell.row.original.order.delivery_end_time ? TimeFormat(cell.row.original.order.delivery_end_time) : 'N/A'),
            },
            {
                accessorKey: 'order.delivery_date',
                header: words.delivery_date,
                Cell: ({ cell }) => DateFormat(cell.getValue<string>()),
            },
            {
                accessorKey: 'order.delivery_height',
                header: words.delivery_height,
            },
            {
                accessorKey: 'order.delivery_price',
                header: words.price,
            },
            {
                accessorKey: 'order.vehicle_id',
                header: words.vehicle,
                Cell: ({ cell }: any) => cell.getValue() ? `${cell.getValue().name} || ${cell.getValue().company.name}` : words.not_selected,
            },
            {
                accessorKey: 'order.done',
                header: words.done,
                Cell: ({ cell }) => cell.getValue<boolean>() ? words.yes : words.no,
            },
            {
                accessorKey: 'actions',
                header: words.actions,
                Cell: ({ row }) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Tooltip title={words.edit_action}>
                            <IconButton onClick={() => handleShowEditOrderModal(row.original)}>
                                <EditIcon color='primary'/>
                            </IconButton>
                        </Tooltip>
                        {(user?.role === 'manager' || user?.is_superuser) && (
                            <Tooltip title={words.delete_action}>
                                <IconButton onClick={() => handleShowDeleteOrderModal(row.original)}>
                                    <DeleteIcon color='error'/>
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                ),
            },
        ],
        [user]
    );

    return (
        <>
            <MaterialReactTable
                columns={columns}
                data={orders}
                enableColumnFilters
                enablePagination
                enableSorting
                muiTableBodyRowProps={({ row }) => ({
                    sx: {
                        backgroundColor: row.original.order.vehicle_id
                            ? row.original.order.done
                                ? '#e8f5e9'  // light green
                                : '#ffcdd2'  // light red
                            : '#fff3e0',  // light orange
                    },
                })}
                muiTableBodyCellProps={{
                    sx: {
                        textAlign: 'left',  // Align text to the left
                    },
                }}
            />

            <CustomModal show={showEditOrderModal} onHide={handleHideEditOrderModal} title={words.edit_action + ' ' + words.order + ' ' + DataElement?.order.customer_name}>
                <EditOrderForm onHide={handleHideEditOrderModal} object={DataElement!} />
            </CustomModal>

            <CustomModal show={showDeleteModal} onHide={handleHideDeleteOrderModal} title={words.delete_action + ' ' + words.order + ' ' + DataElement?.order.customer_name}>
                <DeleteOrderForm onHide={handleHideDeleteOrderModal} object={DataElement!} />
            </CustomModal>
        </>
    );
};

export default OrdersListTable;