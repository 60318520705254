import { useState } from 'react';
import { useQuery } from 'react-query';
import { Loading } from '../components/loading';
import { words } from '../constants/translate';
import { Container, Button, Alert, Typography, Box } from '@mui/material';
import { GetAllOrdersList } from '../redux/services/orderService';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../components/customModal';
import OrdersListTable from '../components/ordersTable';
import { NewOrderForm } from '../components/addNewOrederForm';
import { FiArrowRight } from 'react-icons/fi';

export default function Orders() {

  const [showNewOrder, setShowNewOrder] = useState(false);
  const { data:orders, status, refetch } = useQuery('orders', GetAllOrdersList);
  const navigate = useNavigate();
  
  const handleCloseNewOrderModal = () => {
    setShowNewOrder(false);
    refetch();
  };

  const handleShowNewOrderModal = () => {
    setShowNewOrder(true);
  };

  if (status === 'error' || status === 'idle'){
    return <Alert severity="error">{words.something_wrong}</Alert>
  }

  if (status === 'loading'){
    return <Loading/>
  }

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
        <Button onClick={handleGoBack} startIcon={<FiArrowRight />} variant="contained">
          {words.back}
        </Button>
      </Box>

      <Box sx={{ my: 4, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="success"
          startIcon={<AddCircleIcon />}
          onClick={handleShowNewOrderModal}
        >
          {words.add_new_order}
        </Button>
      </Box>

      <Box sx={{ my: 4 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>{words.orders}</Typography>
        <Box>
          <OrdersListTable orders={orders.data} refresh={refetch} />
        </Box>
      </Box>

      <CustomModal show={showNewOrder} onHide={handleCloseNewOrderModal} title={words.add_new_order}>
        <NewOrderForm refresh={refetch} data={orders.data}/>
      </CustomModal>
    </Box>
  );
}