import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Alert, Typography, Box } from '@mui/material';
import { FiArrowRight } from 'react-icons/fi';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useQuery } from 'react-query';
import { GetTransportPriceList } from '../redux/services/transportServices';
import { Loading } from '../components/loading';
import { words } from '../constants/translate';
import CustomModal from '../components/customModal';
import PricesListTable from '../components/pricesTable';
import { AddPriceForm } from '../components/addNewPriceForm';

function ListPrice() {
  const [addNewPriceTableModalShow, setAddNewPriceTableModalShow] = React.useState(false);
  const navigate = useNavigate();

  const { data: priceData, status, refetch } = useQuery('priceData', GetTransportPriceList);

  if (status === 'loading') {
    return <Loading />;
  }

  if (status === 'error' || status === 'idle') {
    return <Alert severity="error">{words.something_wrong}</Alert>;
  }

  const handleShowNewPriceTableModal = () => {
    setAddNewPriceTableModalShow(true);
  };

  const handleHideNewPriceTableModal = () => {
    setAddNewPriceTableModalShow(false);
    refetch();
  };

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
        <Button 
          onClick={handleGoBack} 
          variant="contained" 
          startIcon={<FiArrowRight />}
        >
          {words.back}
        </Button>
      </Box>

      <Box>
        <Box sx={{ display: 'flex', my: 4, justifyContent: 'center' }}>
          <Typography variant="h4" className="company-details-title">{words.price_table_list}</Typography>
        </Box>

        {/* <Box sx={{ my: 4 }} className="actionBar">
          <Button
            variant="contained"
            color="success"
            className="responsive-button"
            onClick={handleShowNewPriceTableModal}
            startIcon={<AddCircleIcon />}
          >
            {words.add_new_price_table}
          </Button>
        </Box> */}

        <Box sx={{ my: 4 }} className="userTableContainer">
          <Box className="title-container">
            <Typography variant="h5" className="title">{words.price_table_list}</Typography>
          </Box>
          <Box className="TableWrap">
            <PricesListTable prices={priceData.data} refresh={refetch} />
          </Box>
        </Box>
      </Box>

      <CustomModal onHide={handleHideNewPriceTableModal} show={addNewPriceTableModalShow} title={words.add_new_price_table}>
        <AddPriceForm refresh={refetch} />
      </CustomModal>
    </Box>
  );
}

export default ListPrice;
