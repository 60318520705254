import React, { useState, useMemo, useCallback } from 'react';
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { MRT_Localization_HE } from 'material-react-table/locales/he';
import { Box, Button, Typography } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import CustomModal from './customModal';
import { words } from '../constants/translate';
import { DeleteCompanyForm } from './deleteCompanyForm';
import { EditCompanyForm } from './editComapnyForm';
import { COMPANY_API } from '../models/company';

interface LogisticsCompaniesTableProps {
  logisticsCompanies: COMPANY_API.Company[];
  refresh: () => void;
}

export const LogisticsCompaniesTable: React.FC<LogisticsCompaniesTableProps> = ({ logisticsCompanies, refresh }) => {
  const [targetCompany, setTargetCompany] = useState<COMPANY_API.Company | null>(null);
  const [showEditCompanyModal, setShowEditCompanyModal] = useState(false);
  const [showDeleteCompanyModal, setShowDeleteCompanyModal] = useState(false);
  const [targetID, setTargetID] = useState('');
  const [companyNameTarget, setCompanyNameTarget] = useState('');

  const navigate = useNavigate();

  const handleShowEditCompanyModal = useCallback((company: COMPANY_API.Company) => {
    setShowEditCompanyModal(true);
    setTargetCompany(company);
  }, []);

  const handleHideEditCompanyModal = useCallback(() => {
    setShowEditCompanyModal(false);
    refresh();
  }, [refresh]);

  const handleShowDeleteCompanyModal = useCallback((id: string, name: string) => {
    setShowDeleteCompanyModal(true);
    setTargetID(id);
    setCompanyNameTarget(name);
  }, []);

  const handleHideDeleteCompanyModal = useCallback(() => {
    setShowDeleteCompanyModal(false);
    setTargetID('');
    setCompanyNameTarget('');
    refresh();
  }, [refresh]);

  const handleRowClick = useCallback((id: string) => {
    navigate(`/logistics/${id}`);
  }, [navigate]);

  const columns = useMemo<MRT_ColumnDef<COMPANY_API.Company>[]>(() => [
    {
      accessorKey: 'name',
      header: words.company_name,
      Cell: ({ row }) => (
        <Box
          component="span"
          sx={{
            cursor: 'pointer',
            color: 'primary.main',
            '&:hover': { textDecoration: 'underline' },
            fontSize: '1rem',
            textAlign: 'left',
            width: '100%',
            display: 'block'
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleRowClick(row.original.id);
          }}
        >
          {row.original.name}
        </Box>
      ),
    },
    {
      accessorKey: 'mobile_number_1',
      header: `${words.mobile_number} 1`,
      Cell: ({ cell }) => <Box sx={{ direction: 'ltr', textAlign: 'left' }}>{cell.getValue<string>()}</Box>,
    },
    {
      accessorKey: 'mobile_number_2',
      header: `${words.mobile_number} 2`,
      Cell: ({ cell }) => <Box sx={{ direction: 'ltr', textAlign: 'left' }}>{cell.getValue<string>()}</Box>,
    },
  ], [handleRowClick]);

  const table = useMaterialReactTable({
    columns,
    data: logisticsCompanies,
    enableColumnFilters: true,
    enableGlobalFilter: true,
    enablePagination: true,
    enableSorting: true,
    muiTableBodyRowProps: ({ row }) => ({
      sx: { backgroundColor: row.original.is_active ? '#e8f5e9' : '#ffcdd2' },
      // Remove the onClick handler from here
    }),
    enableRowActions: true,
    positionActionsColumn: 'last',
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'nowrap', justifyContent: 'flex-start' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={(e) => {
            e.stopPropagation();
            handleShowEditCompanyModal(row.original);
          }}
          startIcon={<EditIcon />}
          sx={{ fontSize: '0.875rem', whiteSpace: 'nowrap' }}
        >
          {words.edit_action}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={(e) => {
            e.stopPropagation();
            handleShowDeleteCompanyModal(row.original.id, row.original.name);
          }}
          startIcon={<DeleteIcon />}
          sx={{ fontSize: '0.875rem', whiteSpace: 'nowrap' }}
        >
          {words.delete_action}
        </Button>
      </Box>
    ),
    muiTableHeadCellProps: {
      sx: {
        fontSize: '1rem',
        fontWeight: 'bold',
        textAlign: 'right',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '1rem',
        textAlign: 'right',
      },
    },
    localization: MRT_Localization_HE,
    initialState: { density: 'comfortable', showGlobalFilter: true },
  });

  return (
    <>
      <MaterialReactTable table={table} />

      <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem', mt: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <Box sx={{ width: 20, height: 20, backgroundColor: '#ffcdd2' }} />
          <Typography>{words.active}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <Box sx={{ width: 20, height: 20, backgroundColor: '#e8f5e9' }} />
          <Typography>{words.deactivate}</Typography>
        </Box>
      </Box>

      <CustomModal show={showEditCompanyModal} onHide={handleHideEditCompanyModal} title={`${words.edit_company_information} ${targetCompany?.name}`}>
        <EditCompanyForm onHide={handleHideEditCompanyModal} company={targetCompany!} />
      </CustomModal>

      <CustomModal show={showDeleteCompanyModal} onHide={handleHideDeleteCompanyModal} title={`${words.delete_company} ${companyNameTarget}`}>
        <DeleteCompanyForm onHide={handleHideDeleteCompanyModal} id={targetID} company_name={companyNameTarget} />
      </CustomModal>
    </>
  );
};