import React from 'react';
import '../assets/CSS/user.css';
import { useQuery } from 'react-query';
import { GetAllUsersService } from '../redux/services/userManagmentService';
import { Loading } from '../components/loading';
import { UserTable } from '../components/userTable';
import { FaCaretUp, FaCaretDown, FaPlusCircle } from 'react-icons/fa';
import { FiArrowRight } from 'react-icons/fi';
import { Container, Button, Alert, IconButton, Typography, Box } from '@mui/material';
import { CustomModal } from '../components/customModal';
import { AddUserForm } from '../components/addNewUserForm';
import { words } from '../constants/translate';
import { useNavigate } from 'react-router-dom';

export default function Users() {
  const navigate = useNavigate();
  const [showAddNewUserModal, setShowAddNewUserModal] = React.useState(false);
  const [showTable, setShowTable] = React.useState(true);
  
  const { data, status, refetch } = useQuery('users', GetAllUsersService);

  const handleCloseNewUserModal = () => {
    setShowAddNewUserModal(false);
    refetch();
  };

  const handleShowNewUserModal = () => setShowAddNewUserModal(true);
  const handleGoBack = () => navigate('/');
  const toggleTable = () => setShowTable(prev => !prev);

  if (status === 'loading') return <Loading />;

  return (
    <Container maxWidth={false}>
      <Box display="flex" justifyContent="flex-start" mt={2}>
        <Button
          variant="contained"
          onClick={handleGoBack}
          className="back-button responsive-button"
          startIcon={<FiArrowRight />}
        >
          {words.back}
        </Button>
      </Box>

      <Box my={4} className='actionBar'>
        <Button
          variant="contained"
          color="success"
          onClick={handleShowNewUserModal}
          startIcon={<FaPlusCircle />}
        >
          {words.add_new_user}
        </Button>
      </Box>

      <Box className="userTableContainer" my={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center" className="title-container">
          <Typography variant="h5" className="title">{words.user_list}</Typography>
          <IconButton onClick={toggleTable} style={{ zIndex: 2 }}>
            {showTable ? (
              <FaCaretUp fontSize="large" className="icon-up" />
            ) : (
              <FaCaretDown fontSize="large" className="icon-down" />
            )}
          </IconButton>
        </Box>
        <Box className={`TableWrap ${showTable ? 'showTable' : 'hideTable'}`}>
          <UserTable users={data?.data} refresh={refetch} />
        </Box>
      </Box>

      <CustomModal show={showAddNewUserModal} onHide={handleCloseNewUserModal} title={words.add_new_user}>
        <AddUserForm refresh={refetch} />
      </CustomModal>
    </Container>
  );
}
