import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../assets/CSS/companyDetails.css';
import { Alert, Button, Typography, Box } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useQuery } from 'react-query';
import { GetAllVehiclesForCompany } from '../redux/services/logisticsServices';
import { Loading } from '../components/loading';
import { words } from '../constants/translate';
import { VehiclesTable } from '../components/vehiclesTable';
import CustomModal from '../components/customModal';
import { AddVehicleForm } from '../components/addNewVehicleForm';
import { FiArrowRight } from 'react-icons/fi';
import { Card, CardContent } from '@mui/material';
import { FaPlusCircle } from 'react-icons/fa';

function LogisticsCompanyDetails() {
  const [addNewVehicleModalShow, setAddNewVehicleModalShow] = React.useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  if (!id) {
    navigate('/');
  }
  const { data:vehicles, status, refetch } = useQuery('vehicles', () => GetAllVehiclesForCompany(id!));

  if (status === 'error' || status === 'idle') {
    return <Alert severity="error">{words.something_wrong}</Alert>;
  }

  if (status === 'loading') {
    return <Loading/>;
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  const handelShowNewVehicleModa = () => {
    setAddNewVehicleModalShow(true);
  };
  const handelHideNewVehicleModa = () => {
    setAddNewVehicleModalShow(false);
    refetch();
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
        <Button onClick={handleGoBack} startIcon={<FiArrowRight />} variant="contained">
          {words.back}
        </Button>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
        {vehicles && vehicles.data && vehicles.data.length !== 0 && (
          <Typography variant="h4" component="h2">
            {`${words.company_details}: ${vehicles.data[0].company?.name}`}
          </Typography>
        )}
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'flex-start', my: 2 }}>
        <Card sx={{ bgcolor: 'background.paper', borderRadius: 1, width: 200, height: 200, border: '1px solid #e0e0e0' }}>
          <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h6" align="center">
              {words.total_vehicles}
            </Typography>
            <Typography variant="h4" align="center" sx={{ mt: 2 }}>
              {vehicles.data.length}
            </Typography>
          </CardContent>
        </Card>
      </Box>

      <div className="my-4 actionBar">
          <Button color='success' variant='contained' onClick={handelShowNewVehicleModa} startIcon={<FaPlusCircle />}>
            {words.add_new_vehicle}
          </Button>
      </div>

      <Box sx={{ my: 4 }}>
        <Typography variant="h5" component="h3" gutterBottom>
          {words.vehicles_list}
        </Typography>
        <VehiclesTable vehicles={vehicles.data} refresh={refetch} />
      </Box>

      <CustomModal onHide={handelHideNewVehicleModa} show={addNewVehicleModalShow} title={words.add_new_vehicle}>
        <AddVehicleForm refresh={refetch} company_id={id!} />
      </CustomModal>
    </Box>
  );
}

export default LogisticsCompanyDetails;
