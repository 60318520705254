import React, { useState, useMemo, useCallback } from 'react';
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { MRT_Localization_HE } from 'material-react-table/locales/he';
import { Box, Button } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { words } from '../constants/translate';
import CustomModal from './customModal';
import { EditPasswordForm } from '../components/editPasswordForm';
import { DeleteUserForm } from '../components/deleteUserForm';
import { getUser } from '../helper/authUtils';

interface User {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  role: string;
  last_login: string;
  is_active: boolean;
}

interface UserTableProps {
  users: User[];
  refresh: () => void;
}

export const UserTable: React.FC<UserTableProps> = ({ users, refresh }) => {
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [targetID, setTargetID] = useState('');
  const [usernameTarget, setUsernameTarget] = useState('');

  const currentUser = getUser();

  const handleShowEditUserModal = useCallback((id: string, username: string) => {
    setShowEditUserModal(true);
    setTargetID(id);
    setUsernameTarget(username);
  }, []);

  const handleHideEditUserModal = useCallback(() => {
    setShowEditUserModal(false);
    setTargetID('');
    setUsernameTarget('');
    refresh();
  }, [refresh]);

  const handleShowDeleteUserModal = useCallback((id: string, username: string) => {
    setShowDeleteUserModal(true);
    setTargetID(id);
    setUsernameTarget(username);
  }, []);

  const handleHideDeleteUserModal = useCallback(() => {
    setShowDeleteUserModal(false);
    setTargetID('');
    setUsernameTarget('');
    refresh();
  }, [refresh]);

  const columns = useMemo<MRT_ColumnDef<User>[]>(() => [
    {
      accessorKey: 'username',
      header: words.username,
      Cell: ({ cell }) => (
        <Box sx={{ direction: 'ltr', textAlign: 'left' }}>{cell.getValue<string>()}</Box>
      ),
    },
    {
      accessorFn: (row) => `${row.first_name} ${row.last_name}`,
      id: 'fullName',
      header: words.name,
      Cell: ({ cell }) => (
        <Box sx={{ direction: 'ltr', textAlign: 'left' }}>{cell.getValue<string>()}</Box>
      ),

    },
    {
      accessorKey: 'phone_number',
      header: words.mobile_number,
      Cell: ({ cell }) => (
        <Box sx={{ direction: 'ltr', textAlign: 'left' }}>{cell.getValue<string>()}</Box>
      ),
    },
    {
      accessorKey: 'role',
      header: words.role,
      Cell: ({ cell }) => (
        <Box sx={{ direction: 'ltr', textAlign: 'left' }}>{cell.getValue<string>() === 'worker' ? words.worker : words.manager}</Box>
      ),
    },
  ], []);

  const table = useMaterialReactTable({
    columns,
    data: users,
    enableColumnFilters: true,
    enableGlobalFilter: true,
    enablePagination: true,
    enableSorting: true,
    muiTableBodyRowProps: ({ row }) => ({
      sx: { backgroundColor: row.original.is_active ? '#e8f5e9' : '#ffcdd2' },
    }),
    enableRowActions: currentUser?.is_superuser,
    positionActionsColumn: 'last',
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'nowrap', justifyContent: 'flex-start' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleShowEditUserModal(`${row.original.id}`, row.original.username)}
          startIcon={<EditIcon />}
          sx={{ fontSize: '0.875rem', whiteSpace: 'nowrap' }}
        >
          {words.edit_action}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => handleShowDeleteUserModal(`${row.original.id}`, row.original.username)}
          startIcon={<DeleteIcon />}
          sx={{ fontSize: '0.875rem', whiteSpace: 'nowrap' }}
        >
          {words.delete_action}
        </Button>
      </Box>
    ),
    muiTableHeadCellProps: {
      sx: {
        fontSize: '1rem',
        fontWeight: 'bold',
        textAlign: 'right',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '1rem',
        textAlign: 'right',
      },
    },
    localization: MRT_Localization_HE,
    initialState: { density: 'comfortable', showGlobalFilter: true },
  });

  return (
    <>
      <MaterialReactTable table={table} />

      <CustomModal 
        show={showEditUserModal} 
        onHide={handleHideEditUserModal} 
        title={`${words.edit_action} ${words.password} ${usernameTarget}`}
      >
        <EditPasswordForm id={targetID} onHide={handleHideEditUserModal} />
      </CustomModal>

      <CustomModal 
        show={showDeleteUserModal} 
        onHide={handleHideDeleteUserModal} 
        title={`${words.delete_action} ${usernameTarget}`}
      >
        <DeleteUserForm onHide={handleHideDeleteUserModal} id={targetID} username={usernameTarget} />
      </CustomModal>
    </>
  );
};