import React, { useState, useMemo, useCallback } from 'react';
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { MRT_Localization_HE } from 'material-react-table/locales/he';
import { Form, InputGroup, Col, Row, Pagination } from 'react-bootstrap';
import { words } from '../constants/translate';
import '../assets/CSS/report.css';
import { REQUEST_API } from '../models/request';
import { DateFormat, TimeFormat } from '../helper/time';

interface OrdersListTableProps {
    requests: REQUEST_API.Request[];
}

const RequestReport: React.FC<OrdersListTableProps> = ({ requests }) => {
    const getStartOfMonth = (): string => {
        const date = new Date();
        date.setDate(1);
        return date.toISOString().slice(0, 10);
    };

    const getEndOfMonth = (): string => {
        const date = new Date();
        date.setMonth(date.getMonth() + 1);
        date.setDate(0);
        return date.toISOString().slice(0, 10);
    };

    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [startDate, setStartDate] = useState<string>(getStartOfMonth());
    const [endDate, setEndDate] = useState<string>(getEndOfMonth());

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const filteredRequest = useMemo(() => {
        return [...requests]
            .sort((a: any, b: any) => b?.id - a?.id)
            .filter((record: any) => {
                const searchValue = searchTerm.toLowerCase();
                return (
                    record.request.supplier.name.toLowerCase().includes(searchValue) ||
                    record.request.supplier.whatsapp_number.toLowerCase().includes(searchValue) ||
                    record.request.created_by.username.toLowerCase().includes(searchValue)
                );
            });
    }, [requests, searchTerm]);

    const dateFilteredRequests = useMemo(() => {
        return filteredRequest.filter((request: any) => {
            const RequestDate = new Date(request.request.create_date);
            const start = new Date(startDate);
            const end = new Date(endDate);
            const dateCondition = (!startDate || RequestDate >= start) && (!endDate || RequestDate <= end);
            return dateCondition;
        });
    }, [filteredRequest, startDate, endDate]);

    const totalRequest = dateFilteredRequests.length;
    const canceledRequests = dateFilteredRequests.filter(request => request.canceled).length;

    const columns = useMemo<MRT_ColumnDef<REQUEST_API.Request>[]>(() => [
        { accessorKey: 'request.id', header: words.request_id },
        { accessorKey: 'request.created_by.username', header: words.request_send_by },
        { accessorKey: 'request.supplier.name', header: words.supplier_name },
        { accessorKey: 'request.delivery_date', header: words.delivery_date, Cell: ({ cell }: any) => DateFormat(cell.getValue()) },
        { accessorKey: 'request.delivery_start_time', header: words.delivery_time, Cell: ({ cell, row }: any) => `${TimeFormat(cell.getValue())}-${TimeFormat(row.original.request.delivery_end_time)}` },
        { accessorKey: 'request.create_date', header: words.request_send_date, Cell: ({ cell }: any) => DateFormat(cell.getValue()) },
        { accessorKey: 'request.create_time', header: words.request_send_time, Cell: ({ cell }: any) => TimeFormat(cell.getValue()) },
    ], []);

    const table = useMaterialReactTable({
        columns,
        data: dateFilteredRequests,
        enableColumnFilters: true,
        enableGlobalFilter: true,
        enablePagination: true,
        enableSorting: true,
        muiTableHeadCellProps: {
            sx: {
                fontSize: '1rem',
                fontWeight: 'bold',
                textAlign: 'right',
            },
        },
        muiTableBodyCellProps: {
            sx: {
                fontSize: '1rem',
                textAlign: 'right',
            },
        },
        localization: MRT_Localization_HE,
        initialState: { density: 'comfortable', showGlobalFilter: true },
    });

    return (
        <>
            <div className="d-flex justify-content-around my-4">
                <div className="report-card text-center">
                    <div className="report-card-body">
                        <h5 className="report-card-title">{words.total_request}</h5>
                        <p className="report-card-text">{totalRequest}</p>
                    </div>
                </div>
                <div className="report-card text-center">
                    <div className="report-card-body">
                        <h5 className="report-card-title">{words.canceled_request}</h5>
                        <p className="report-card-text">{canceledRequests}</p>
                    </div>
                </div>
            </div>
            <Form className="my-2">
                <Form.Group controlId="search">
                    <InputGroup>
                        <Form.Control
                            className='customSearch p-3'
                            type="text"
                            placeholder={words.search}
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                    </InputGroup>
                </Form.Group>

                <Row className='m-3'>
                    <Form.Group as={Col} md={3} controlId="startDate" className="d-flex align-items-center">
                        <Form.Label className="date-label">{words.start_date}</Form.Label>
                        <Form.Control type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                    </Form.Group>

                    <Form.Group as={Col} md={3} controlId="endDate" className="d-flex align-items-center">
                        <Form.Label className="date-label">{words.end_date}</Form.Label>
                        <Form.Control type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                    </Form.Group>
                </Row>
            </Form>

            <MaterialReactTable table={table} />

            <div className="d-flex justify-content-center">
                <Pagination>
                    {Array.from({ length: Math.ceil(dateFilteredRequests.length / itemsPerPage) }).map((_, index) => (
                        <Pagination.Item
                            key={index}
                            active={index + 1 === currentPage}
                            onClick={() => setCurrentPage(index + 1)}
                        >
                            {index + 1}
                        </Pagination.Item>
                    ))}
                </Pagination>
            </div>

            <div className="agenda-boxes">
                <div className="agenda-box">
                    <div className="box redRow"></div>
                    <div className="box-title">{words.canceled_request}</div>
                </div>
                <div className="agenda-box">
                    <div className="box greenRow"></div>
                    <div className="box-title">{words.done}</div>
                </div>
            </div>
        </>
    );
};

export default RequestReport;
