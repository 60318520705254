import * as React from 'react';
import { useQuery } from 'react-query';
import { GetAllLogisticsCompanies } from '../redux/services/logisticsServices';
import { LogisticsCompaniesTable } from '../components/logisticsCompaniesTable';
import '../assets/CSS/logistics.css';
import { Loading } from '../components/loading';
import { words } from '../constants/translate';
import { Container, Button, Alert, Typography, Box } from '@mui/material';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import CustomModal from '../components/customModal';
import { AddCompanyForm } from '../components/addNewCompanyForm';
import { useNavigate } from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';

export interface ILogisticsProps {}

export default function Logistics(props: ILogisticsProps) {
  const [showNewCompany, setShowNewCompany] = React.useState(false);
  const { data: companies, status, refetch } = useQuery('companies', GetAllLogisticsCompanies);
  const navigate = useNavigate()
  
  const handleCloseNewCompanyModal = () => {
    setShowNewCompany(false);
    refetch();
  };

  const handleShowNewCompanyModal = () => {
    setShowNewCompany(true);
  };

  if (status === 'loading') {
    return <Loading />;
  }
  
  if (status === 'error' || status === 'idle') {
    return <Alert severity="error">{words.something_wrong}</Alert>
  }

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
      <Button onClick={handleGoBack} startIcon={<FiArrowRight />} variant="contained">
          {words.back}
        </Button>
      </Box>
     
      <Box sx={{ my: 4, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="success"
          startIcon={<AddCircleIcon />}
          onClick={handleShowNewCompanyModal}
        >
          {words.add_new_company}
        </Button>
      </Box>

      <Box sx={{ my: 4 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          {words.logistics_companies}
        </Typography>
        <Box>
        <LogisticsCompaniesTable logisticsCompanies={companies.data} refresh={refetch} />
        </Box>
      </Box>

      <CustomModal show={showNewCompany} onHide={handleCloseNewCompanyModal} title={words.add_new_company}>
        <AddCompanyForm refresh={refetch} />
      </CustomModal>
    </Box>
  );
}
