import { useState } from 'react';
import { useQuery } from 'react-query';
import { Loading } from '../components/loading';
import { words } from '../constants/translate';
import { Container, Button, Alert, Dialog, DialogTitle, DialogContent, Box, Typography } from '@mui/material';
import { Add as AddIcon, ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { GetAllRequest } from '../redux/services/reuqestServer';
import RequestTable from '../components/requestTable';
import { AddNewRequestForm } from '../components/addNewRequestForm';
import { GetAllOrdersList } from '../redux/services/orderService';
import { FiArrowRight } from 'react-icons/fi';
import CustomModal from '../components/customModal';

export default function Requests() {

  const [showNewRequest, setShowNewRequest] = useState(false);
  const { data:requests, status, refetch } = useQuery('requests', GetAllRequest);
  const { data:orders, status:order_status} = useQuery('orders', GetAllOrdersList);
  const navigate = useNavigate();
  
  const handleCloseNewRequestModal = () => {
    refetch();
    setShowNewRequest(false);
  };

  const handleShowNewRequestModal = () => {
    setShowNewRequest(true);
  };

  if (status === 'error' || order_status === 'error'){
    return <Alert severity="error">{words.something_wrong}</Alert>
  }

  if (status === 'loading' || order_status === 'loading'){
    return <Loading/>
  }

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
        <Button onClick={handleGoBack} startIcon={<FiArrowRight />} variant="contained">
          {words.back}
        </Button>
      </Box>

      <Box sx={{ my: 4, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="success"
          startIcon={<AddIcon />}
          onClick={handleShowNewRequestModal}
        >
          {words.add_new_request}
        </Button>
      </Box>

      <Box sx={{ my: 4 }}>
        <Typography variant="h5" component="h3" gutterBottom>
          {words.request_page}
        </Typography>
        <Box sx={{ mt: 2 }}>
          <RequestTable data={requests.data} refresh={refetch} />
        </Box>
      </Box>

      
      <CustomModal onHide={handleCloseNewRequestModal} show={showNewRequest} title={words.add_new_request}>
          <AddNewRequestForm refresh={refetch} data={orders.data} />
      </CustomModal>
    </Box>
  );
}