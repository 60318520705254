import * as Yup from 'yup';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Form, Button, Alert } from 'react-bootstrap';
import { words } from '../constants/translate';
import { useMutation, useQuery } from 'react-query';
import { GetAllSupplier } from '../redux/services/supplierService';
import { LOGISTICS_ENDPOINT, REQUEST_ENDPOINT } from '../constants/endpoints';
import apiInstance from '../helper/axiosInstance';
import { SUPPLIER_API } from '../models/supplier';
import { CustomInput } from './input';
import { AutocompleteInput } from './autoCompleteInput';
import { areas } from '../constants/cites';
import DatePicker from 'react-datepicker';
import { GetAllItems} from '../redux/services/orderService';
import { ORDER_API } from '../models/order';
import { Loading } from './loading';
import { formatPhoneNumber } from '../helper/mobileNumebr';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { createWhatsAppMessage } from '../helper/whatsApp';
import { TextArea } from './textArea';

interface AddNewRequestFormProps {
    refresh: () => void;
    data: ORDER_API.Data[]
}

const today = new Date();
today.setHours(0, 0, 0, 0);

const twoMonthsFromNow = new Date(today);
twoMonthsFromNow.setMonth(today.getMonth() + 1);

const validationSchema = Yup.object().shape({
    selectedSupplierID: Yup.string().required(words.fields_required),
    customer_name: Yup.string().required(words.fields_required),
    customer_phone: Yup.string().required(words.fields_required).min(8,words.mobile_number_invalid),
    delivery_place: Yup.string().required(words.fields_required).oneOf(areas, 'העיר אינה תקינה'),
    address_details: Yup.string().required(words.fields_required),
    delivery_start_time: Yup.string().required(words.fields_required),
    delivery_date: Yup.date()
        .min(today, 'התאריך חייב להיות היום או אחריו')
        .max(twoMonthsFromNow, 'התאריך חייב להיות לפני שני חודשים מהיום')
        .required(words.fields_required)
        .nullable(),
});


export const AddNewRequestForm: React.FC<AddNewRequestFormProps> = ({ refresh, data }) => {
    const [error, setError] = useState<string>('');
    const [items, setItems] = useState<ORDER_API.Item_list[]>([]);
    const [selectedItem, setSelectedItem] = useState<string | null>(null);
    const [quantity, setQuantity] = useState<string>('1.0');

    const formik = useFormik({
        initialValues: {
            selectedSupplierID: '',
            customer_name: '',
            customer_phone: '',
            delivery_place: '',
            address_details: '',
            delivery_start_time: '',
            delivery_end_time: '',
            delivery_date: null,
            note: '',
            done: false,
        },
        validationSchema,
        onSubmit: (values) => {
            handleSubmitAPI(values);
        },
    });

    const { data: suppliers_data, status: suppliers_status, } = useQuery('suppliers', GetAllSupplier);
    const { data: items_request_data, status: items_request_status, } = useQuery('items_request', GetAllItems);

    const DeleteRequestMutation = useMutation<void, unknown, any>(
        (id) => {
            return apiInstance.delete(LOGISTICS_ENDPOINT + REQUEST_ENDPOINT+`${id}/`)
        }
    );

    const createRequestMutation = useMutation<void, unknown, any>(
        (data) => {
            return apiInstance.post(LOGISTICS_ENDPOINT + REQUEST_ENDPOINT, data).then((resp) => {
                let messageBody = createWhatsAppMessage(resp.data,items);
                const encodedMessage = encodeURIComponent(messageBody);
                const whatsappUrl = `https://wa.me/${resp.data.supplier.whatsapp_number}?text=${encodedMessage}`;
                window.open(whatsappUrl, '_blank');

                const handleFocus = () => {
                    const isDone = window.confirm(words.whatsapp_confirmation_message);
                    if (!isDone) {
                        DeleteRequestMutation.mutate(resp.data.id)
                        
                    } else {
                        toast.success('הפעולה הושלמה בהצלחה')
                        window.removeEventListener('focus', handleFocus);
                        return;

                    }
                    window.removeEventListener('focus', handleFocus);
                    
                };
                window.addEventListener('focus', handleFocus);
                refresh();
            });
        },
        {
            onError: () => {
                setError(words.something_wrong);
            },
        }
    );



    if (suppliers_status === 'error' || items_request_status === 'error') {
        return <Alert variant="danger">{words.something_wrong}</Alert>
    }

    if (suppliers_status === 'loading' || items_request_status === 'loading') {
        return <Loading />
    }
    
    const handleSubmitAPI = async (values: any) => {
        const optionSelected = suppliers_data.data.find((supplier: SUPPLIER_API.Supplier) => supplier.id == values.selectedSupplierID);

        if (!optionSelected) {
            return;
        }

        const NewRequest = {
            supplier: values.selectedSupplierID,
            customer_name: values.customer_name,
            customer_phone: values.customer_phone,
            delivery_place: values.delivery_place,
            address_details: values.address_details,
            delivery_start_time: String(values.delivery_start_time),
            delivery_end_time: values.delivery_end_time ? String(values.delivery_end_time) : String(values.delivery_start_time),
            delivery_date: format(values.delivery_date, 'yyyy-MM-dd'),
            note: values.note,
            items: items,
            done: values.done,
        };

        createRequestMutation.mutate(NewRequest)

    };

    const handleChangeQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
    
        const regex = /^[\d.\u0660-\u0669\u06F0-\u06F9\u0030-\u0039]*$/;
    
        if (!regex.test(inputValue)) {
            return;
        }
    
        setQuantity(inputValue);
    }

    const addItem = () => {
        const optionSelected = items_request_data.data.find((option: ORDER_API.item) => option.name === selectedItem);
        let q_value = Math.round(parseFloat(quantity) * 10) / 10;

        if (q_value < 0.1 || !optionSelected) {
            return;
        }

        setItems(prev => {
            const existingItemIndex = prev.findIndex(item => item.item.id === optionSelected.id);
            
            if (existingItemIndex !== -1) {
                return prev.map((item, index) => 
                    index === existingItemIndex
                        ? { ...item, quantity: item.quantity + q_value }
                        : item
                );
            } else {
                return [...prev, {
                    id: Date.now().toString(),
                    item: optionSelected,
                    quantity: q_value
                }];
            }
        });

        setSelectedItem(null);
        setQuantity('1.0');
    };

    const deleteItem = (id: string) => {
        setItems(prev => prev.filter(item => item.item.id !== id));
    };

    return (
        <Form onSubmit={formik.handleSubmit} className="new-request-form" style={{textAlign:'right'}}>
            {error && <Alert variant="danger">{error}</Alert>}

            <div className="row my-2">
                <div className="col-md-6">
                    <Form.Label>{words.supplier_name}</Form.Label>
                    <Form.Control
                        as="select"
                        name="selectedSupplierID" 
                        value={formik.values.selectedSupplierID}
                        onChange={formik.handleChange}
                    >
                        <>
                            <option value="" disabled>{words.select_supplier}</option>
                            {suppliers_data.data.map((supplier: SUPPLIER_API.Supplier, index: React.Key | null | undefined) => {
                                if (supplier.is_active) {
                                    return <option key={index} value={supplier.id}>{supplier.name}</option>
                                }
                            })}
                        </>
                    </Form.Control>
      
                    {formik.errors.selectedSupplierID && formik.touched.selectedSupplierID && (
                        <div className="text-danger">{formik.errors.selectedSupplierID}</div>
                    )}
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-md-6">
                    <AutocompleteInput
                        name="customer_name" 
                        suggestions={data.map(record=>record.order.customer_name)}
                        showListOnClick={false}
                        matchedRequired={false}
                        label={words.customer_name}
                        type="text"
                        value={formik.values.customer_name}
                        onSuggestionSelect={(value) => formik.setFieldValue('customer_name',value)}
                        onBlur={formik.handleBlur}
                        error={formik.touched.customer_name && formik.errors.customer_name} />                    
                </div>
                <div className="col-md-6">
                    <AutocompleteInput
                        name="customer_phone" 
                        suggestions={data.map(record=>record.order.customer_phone)}
                        showListOnClick={false}
                        matchedRequired={false}
                        label={words.whatsapp_number}
                        type='text'
                        value={formik.values.customer_phone}
                        onSuggestionSelect={(value) => formik.setFieldValue('customer_phone',value)}
                        onBlur={formik.handleBlur}
                        error={formik.touched.customer_phone && formik.errors.customer_phone}  /> 
                </div>
            </div>
            <div className="row mt-2">

                <div className="col-md-6">
                        <AutocompleteInput
                            name="delivery_place"
                            suggestions={areas}
                            label={words.delivery_place}
                            showListOnClick
                            matchedRequired
                            type="text"
                            value={formik.values.delivery_place}
                            onSuggestionSelect={(value) => {
                                formik.setFieldValue('delivery_place', value);
                            }}
                            onBlur={formik.handleBlur}
                            error={formik.touched.delivery_place && formik.errors.delivery_place}
                        />
                </div>

                <div className="col-md-6">
                    <CustomInput
                        name="address_details"
                        label={words.address_details}
                        value={formik.values.address_details}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.address_details && formik.errors.address_details}
                    />
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-6 d-flex flex-column ">
                    <label htmlFor="delivery_dateInput" className="mb-1">
                        {words.delivery_date}
                    </label>

                    <DatePicker
                        id="delivery_dateInput"
                        selected={formik.values.delivery_date}
                        onChange={(date) => formik.setFieldValue('delivery_date', date)}
                        onBlur={formik.handleBlur}
                        className={`form-control ${formik.touched.delivery_date && formik.errors.delivery_date ? 'is-invalid' : ''}`}
                        dateFormat="yyyy-MM-dd"
                        autoComplete="off"
                        name="delivery_date"
                    />
                    {formik.touched.delivery_date && formik.errors.delivery_date ? (
                        <div className="invalid-feedback d-block">{formik.errors.delivery_date}</div>
                    ) : null}

                </div>
            </div>

            <div className='row mb-3'>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="delivery_start_time" className="mb-1">
                            {words.From_time}
                        </label>
                        <input
                            type="time"
                            id="delivery_start_time"
                            name="delivery_start_time"
                            value={formik.values.delivery_start_time}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="off"
                            className={`form-control ${formik.touched.delivery_start_time && formik.errors.delivery_start_time ? 'is-invalid' : ''}`}
                        />
                        {formik.touched.delivery_start_time && formik.errors.delivery_start_time ? (
                            <div className="invalid-feedback">{formik.errors.delivery_start_time}</div>
                        ) : null}
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="delivery_end_time" className="mb-1">
                            {words.To_time}
                        </label>
                        <input
                            type="time"
                            id="delivery_end_time"
                            name="delivery_end_time"
                            value={formik.values.delivery_end_time}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="off"
                            className={`form-control ${formik.touched.delivery_end_time && formik.errors.delivery_end_time ? 'is-invalid' : ''}`}
                        />
                        {formik.touched.delivery_end_time && formik.errors.delivery_end_time ? (
                            <div className="invalid-feedback">{formik.errors.delivery_end_time}</div>
                        ) : null}
                    </div>
                </div>
            </div>

            <div className='row mb-3'>
                    <TextArea 
                        name='note'
                        id='note'
                        label={words.note} 
                        value={formik.values.note} 
                        onChange={formik.handleChange}/> 
            </div>


            <div className='row border m-2'>
                <div className="p-4">
                    <ul className="list-group mb-3 items-list ">
                        {
                            items.length === 0 ?
                                <Alert variant='warning'>{words.add_items}</Alert>
                                :
                                items.map(option => (
                                    <li key={option.id} className="list-group-item d-flex justify-content-between align-items-center">
                                        {option.item.name}  * {option.quantity}
                                        <button className="btn btn-danger btn-sm" onClick={() => deleteItem(option.item.id)}>Delete</button>
                                    </li>
                                ))
                        }
                    </ul>

                    <div className="d-flex justify-content-center">
                        <Form.Group controlId="item" className="mx-2">
                            <Form.Label>{words.select_item}</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedItem || ""}
                                onChange={(e) => setSelectedItem(e.target.value)}
                            >
                                <option value="" disabled>
                                    {words.select_item}
                                </option>

                                {items_request_data.data.map((option: ORDER_API.item, index: React.Key | null | undefined) => (
                                    <option key={index} value={option.name}>{option.name}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <CustomInput
                            label={words.quantity}
                            className="mx-2"
                            value={quantity}
                            onChange={(e) => handleChangeQuantity(e)}
                            placeholder="1"
                            disable={selectedItem ? false : true}
                        />

                        <div style={{ height: '40%' }} className='d-flex align-self-center mt-3 '>
                            <Button
                                className="responsive-button mx-2"
                                size='sm'
                                onClick={addItem}
                                disabled={selectedItem && quantity.length != 0 ? false : true}
                                type='button'>
                                {words.add_items}
                            </Button>
                        </div>

                    </div>
                </div>
            </div>

                <div className="row my-2">
                    <div className="col-md-6">
                        <Form.Check
                            inline
                            type="checkbox"
                            label={words.done}
                            name='done'
                            id='done'
                            checked={formik.values.done}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>

            <div className="d-flex justify-content-center mt-4">
                <Button variant="primary" className="responsive-button" size="lg" type="submit">
                    {words.send_request}
                </Button>
            </div>
        </Form>
    );
};