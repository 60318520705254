import React, { useState, useMemo, useCallback } from 'react';
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { MRT_Localization_HE } from 'material-react-table/locales/he';
import { Box, Button } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import CustomModal from './customModal';
import { PRICE_API } from '../models/price';
import { DeletePriceForm } from './deletePriceForm';
import { EditPriceForm } from './editPriceFrom';
import { words } from '../constants/translate';

interface PricesListTableProps {
  prices: PRICE_API.Price[];
  refresh: () => void;
}

const PricesListTable: React.FC<PricesListTableProps> = ({ prices, refresh }) => {
  const [showEditPriceModal, setShowEditPriceModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [priceObject, setPriceObject] = useState<PRICE_API.Price | null>(null);

  const handleShowEditPriceModal = useCallback((price: PRICE_API.Price) => {
    setShowEditPriceModal(true);
    setPriceObject(price);
  }, []);

  const handleHideEditPriceModal = useCallback(() => {
    setShowEditPriceModal(false);
    refresh();
  }, [refresh]);

  const handleShowDeletePriceModal = useCallback((price: PRICE_API.Price) => {
    setShowDeleteModal(true);
    setPriceObject(price);
  }, []);

  const handleHideDeletePriceModal = useCallback(() => {
    setShowDeleteModal(false);
    refresh();
  }, [refresh]);

  const columns = useMemo<MRT_ColumnDef<PRICE_API.Price>[]>(() => [
    { accessorKey: 'vehicle.company', 
      header: words.company_name,
      Cell: ({ cell }) => <Box sx={{ direction: 'ltr', textAlign: 'left' }}>{cell.getValue<string>()}</Box>,
    },
    { 
      accessorKey: 'vehicle.driver_mobile_number', 
      header: words.driver_mobile_number,
      Cell: ({ cell }) => <Box sx={{ direction: 'ltr', textAlign: 'left' }}>{cell.getValue<string>()}</Box>,
    },
    { accessorKey: 'from_location', header: words.from_location, Cell: ({ cell }) => <Box sx={{ direction: 'ltr', textAlign: 'left' }}>{cell.getValue<string>()}</Box> },
    { accessorKey: 'to_location', header: words.to_location, Cell: ({ cell }) => <Box sx={{ direction: 'ltr', textAlign: 'left' }}>{cell.getValue<string>()}</Box> },
    { accessorKey: 'vehicle.name', header: words.vehicle_name, Cell: ({ cell }) => <Box sx={{ direction: 'ltr', textAlign: 'left' }}>{cell.getValue<string>()}</Box> },
    { accessorKey: 'vehicle.height', header: words.height, Cell: ({ cell }) => <Box sx={{ direction: 'ltr', textAlign: 'left' }}>{cell.getValue<string>()}</Box> },
    { accessorKey: 'vehicle.weight', header: words.weight, Cell: ({ cell }) => <Box sx={{ direction: 'ltr', textAlign: 'left' }}>{cell.getValue<string>()}</Box> },
    { 
      accessorKey: 'price', 
      header: words.price,
      Cell: ({ cell }) => <Box sx={{ direction: 'ltr', textAlign: 'left' }}>₪ {cell.getValue<number>()}</Box>,
    },
  ], []);

  const table = useMaterialReactTable({
    columns,
    data: prices,
    enableColumnFilters: true,
    enableGlobalFilter: true,
    enablePagination: true,
    enableSorting: true,
    enableRowActions: false,
  
    positionActionsColumn: 'last',
    // renderRowActions: ({ row }) => (
    //   <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'nowrap', justifyContent: 'flex-start' }}>
    //     <Button
    //       variant="contained"
    //       color="primary"
    //       onClick={() => handleShowEditPriceModal(row.original)}
    //       startIcon={<EditIcon />}
    //       sx={{ fontSize: '0.875rem', whiteSpace: 'nowrap' }}
    //     >
    //       {words.edit_action}
    //     </Button>
    //     <Button
    //       variant="contained"
    //       color="error"
    //       onClick={() => handleShowDeletePriceModal(row.original)}
    //       startIcon={<DeleteIcon />}
    //       sx={{ fontSize: '0.875rem', whiteSpace: 'nowrap' }}
    //     >
    //       {words.delete_action}
    //     </Button>
    //   </Box>
    // ),
    muiTableHeadCellProps: {
      sx: {
        fontSize: '1rem',
        fontWeight: 'bold',
        textAlign: 'right',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '1rem',
        textAlign: 'right',
      },
    },
    localization: MRT_Localization_HE,
    initialState: { density: 'comfortable', showGlobalFilter: true },
  });

  return (
    <>
      <MaterialReactTable table={table} />

      <CustomModal 
        show={showEditPriceModal} 
        onHide={handleHideEditPriceModal} 
        title={`${words.edit_action} ${priceObject?.from_location} ${words.to} ${priceObject?.to_location} ${words.for} ${priceObject?.vehicle.name}`}
      >
        <EditPriceForm onHide={handleHideEditPriceModal} price={priceObject!} />
      </CustomModal>

      <CustomModal 
        show={showDeleteModal} 
        onHide={handleHideDeletePriceModal} 
        title={`${words.delete_action} ${priceObject?.from_location} ${words.to} ${priceObject?.to_location} ${words.for} ${priceObject?.vehicle.name}`}
      >
        <DeletePriceForm onHide={handleHideDeletePriceModal} price={priceObject!} />
      </CustomModal>
    </>
  );
};

export default PricesListTable;