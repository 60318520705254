import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { MRT_Localization_HE } from 'material-react-table/locales/he';
import { cites } from '../constants/cites';
import { COMPANY_API } from '../models/company';
import { useQuery, useMutation } from 'react-query';
import { price_entries } from '../redux/services/logisticsServices';
import { Loading } from './loading';
import { LOGISTICS_ENDPOINT } from '../constants/endpoints';
import { toast } from 'react-toastify';
import apiInstance from '../helper/axiosInstance';
import { words } from '../constants/translate';
import { produce } from 'immer';

type Props = {
  vehicle: COMPANY_API.Vehicle
}

type Price = {
  id: string;
  from_location: string;
  to_location: string;
  price: string;
}

const inputStyles = {
  padding: '0.5rem',
  borderRadius: '4px',
  border: '1px solid #ccc',
  transition: 'opacity 0.3s ease',
  width: '100%',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  textAlign: 'right' as const,
};

const VehiclePriceList = ({ vehicle }: Props) => {
  const { data: price, status, refetch } = useQuery('price_entries', () => price_entries(vehicle.id));
  
  const priceMutation = useMutation(
    (data) => apiInstance.post(LOGISTICS_ENDPOINT + 'transport_price_entry/', data),
    {
      onSuccess: () => {
        toast.success(words.done);
        refetch();
      },
      onError: () => {
        toast.error(words.something_wrong);
      }
    }
  );

  const processedData = useMemo(() => {
    if (!price) return [];
    const data = [...price.data];
    const existingToLocations = new Set(data.map((record: Price) => record.to_location));
    let maxId = Math.max(...data.map((record: Price) => parseInt(record.id)));

    cites.forEach(city => {
      if (!existingToLocations.has(city)) {
        maxId += 1;
        data.push({ id: maxId.toString(), from_location: "ג'לג'וליה", to_location: city, price: '' });
      }
    });

    return data;
  }, [price]);

  const [tableData, setTableData] = useState<Price[]>([]);
  const [inputValues, setInputValues] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    setTableData(processedData);
  }, [processedData]);

  const handlePriceChange = useCallback((id: string, newPrice: string) => {
    const numericPrice = newPrice.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    
    setInputValues(prev => ({ ...prev, [id]: numericPrice }));

    setTableData(produce(draft => {
      const row = draft.find(r => r.id === id);
      if (row) row.price = numericPrice;
    }));
  }, []);

  const sendPriceUpdate = useCallback((data: any) => {
    if (data.price && parseFloat(data.price) !== 0) {
      priceMutation.mutate(data);
    }
  }, [priceMutation]);

  const handleBlur = useCallback((rowData: any, price: string) => {
    const updatedRowData = {
      vehicle_id: vehicle.id,
      from_location: rowData.from_location,
      to_location: rowData.to_location,
      price: parseFloat(price) || 0
    };
    sendPriceUpdate(updatedRowData);
  }, [vehicle.id, sendPriceUpdate]);

  const columns = useMemo<MRT_ColumnDef<Price>[]>(() => [
    {
      accessorKey: 'from_location',
      header: words.from_location,
    },
    {
      accessorKey: 'to_location',
      header: words.to_location,
    },
    {
      accessorKey: 'price',
      header: words.price,
      Cell: ({ cell, row }) => (
        <input
          style={inputStyles}
          type="text"
          value={inputValues[row.id] ?? cell.getValue<string>()}
          onChange={(e) => handlePriceChange(row.id, e.target.value)}
          onBlur={(e) => handleBlur(row.original, e.target.value)}
          inputMode="decimal"
        />
      ),
    },
  ], [inputValues, handlePriceChange, handleBlur]);

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    enableColumnFilters: true,
    enableGlobalFilter: true,
    enablePagination: true,
    enableSorting: true,
    muiTableProps: {
      sx: {
        tableLayout: 'fixed',
      },
    },
    localization: MRT_Localization_HE,
    muiTableBodyCellProps: {
      sx: {
        textAlign: 'right',
      },
    },
    muiTableHeadCellProps: {
      sx: {
        textAlign: 'right',
      },
    },
    state: {
      isLoading: status === 'loading',
    },
    initialState: {
      density: 'compact',
      pagination: { pageSize: 20, pageIndex: 0 },
      showGlobalFilter: true,
    },
  });

  if (status === 'loading') {
    return <Loading />;
  }

  return <MaterialReactTable table={table} />;
};

export default VehiclePriceList;