import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useMutation } from 'react-query';
import apiInstance from '../helper/axiosInstance';
import { Button, Alert } from 'react-bootstrap';
import { words } from '../constants/translate';
import { COMPANY_API } from '../models/company';
import { LOGISTICS_ENDPOINT } from '../constants/endpoints';
import { formatPhoneNumber } from '../helper/mobileNumebr';
import { toast } from 'react-toastify';
import { CustomInput } from './input';

const validationSchema = Yup.object({
  name: Yup.string()
    .required(words.fields_required),
  mobile_number_1: Yup.string()
    .min(8,words.mobile_number_invalid)
    .required(words.fields_required),
  mobile_number_2: Yup.string()
    .min(8,words.mobile_number_invalid)
  
});

interface AddCompanyFormProps {
  refresh: () => void;
}

export const AddCompanyForm: React.FC<AddCompanyFormProps> = ({ refresh }) => {
  const [error, setError] = useState('');

  const addCompanyMutation = useMutation<void, unknown, COMPANY_API.NewCompany>(
    (data) => {
      return apiInstance.post(LOGISTICS_ENDPOINT + 'logistics-companies/', data).then(() => {
        refresh();
        toast.success('הפעולה הושלמה בהצלחה');
      });
    },
    {
      onError: () => {
        setError(words.something_wrong);
      },
    }
  );

  const handleSubmit = (values:any, reset:()=>void) => {
    const data = {
      name:values.name,
      mobile_number_1:formatPhoneNumber(String(values.mobile_number_1)),
      mobile_number_2:formatPhoneNumber(String(values.mobile_number_2))
    }
    
    addCompanyMutation.mutate(values,{
      onSuccess:()=>{
        reset()
      }
    });
  };

  return (
    <Formik
      initialValues={{
        name: '',
        mobile_number_1: '',
        mobile_number_2: ''
      }}
      validationSchema={validationSchema}
      onSubmit={(values, {resetForm }) => {
        handleSubmit(values,resetForm);
      }}
    >
      {({ errors, touched, handleChange, handleBlur, values }) => (
        <Form className="my-4">
          {error &&<Alert variant="danger">{error}</Alert>}

          <div className="row">
            <div className="col-md-6 my-2">
              <CustomInput
                label={words.company_name}
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.name && touched.name ? errors.name : undefined}
                isRTL={true}
              />
            </div>

            <div className="col-md-6 my-2">
              <CustomInput
                label={`${words.mobile_number} 1`}
                name="mobile_number_1"
                value={values.mobile_number_1}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.mobile_number_1 && touched.mobile_number_1 ? errors.mobile_number_1 : undefined}
                isRTL={true}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 my-2">
              <CustomInput
                label={`${words.mobile_number} 2`}
                name="mobile_number_2"
                value={values.mobile_number_2}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.mobile_number_2 && touched.mobile_number_2 ? errors.mobile_number_2 : undefined}
                isRTL={true}
              />
            </div>
          </div>

          <Button variant="primary" className="mt-4" type='submit'>
            {words.add_new_company}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
